@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

.color-picker{
    max-width: 180px;
}

.numbers{
    max-width:180px;
}

.full-width{
    .row{
        min-width: 100%;
    }
}

p{
    padding: 0;
    margin: 0;
}

.search-overlay{
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    display: none;
    .search-title{
        margin-top: 20%;
    }
}

.ss-SearchOverlayClose{
    cursor: pointer;
    position:absolute;
    top: 32px;
    right: 32px;
}
.search-form{
    .row{
        max-width: 480px;
    }
    .search-input{
        border: none;
        box-shadow: none;
        background: inherit;
        text-align: center;
        font-size: 24px;
    }
    .search-input:focus{
        border: none;
        box-shadow: none;
    }
    .search-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #666;
    }
    .search-input::-moz-placeholder { /* Firefox 19+ */
        color: #666;
    }
    .search-input:-ms-input-placeholder { /* IE 10+ */
        color: #666;
    }
    .search-input:-moz-placeholder { /* Firefox 18- */
        color: #666;
    }
}

.off-canvas{
    li{
        display: block;
    }
}

#offCanvas {
    background-color: white;
}


blockquote {
    font-size: 18px;
    line-height: 26px;
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    color: #000000;
    margin: 1.5rem 0 1.5rem;
    padding: 0rem 0rem 0rem 0rem;
    margin-bottom: 1em;
    border-left: none;
}



blockquote{ 
    p{
        font-family: 'Roboto',sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #253746;
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding: 1.5rem;
        margin: 1.5rem 0 1.5rem;
        background-color: #FFFBEB;
        margin-bottom: 1.5rem;
        border-left: 2px solid #E9EBEC; 
    }
}

.sidebar {
    .widget-area{
        > div{
            margin-bottom: 40px;
        }
    }
}

$editorSelector: '.wysiwig-affected *:not(strong):not(em):not(span):not(blockquote):not(a)';

.sow-accordion p:last-child #{$editorSelector},.textwidget p:last-child #{$editorSelector},.textwidget pre:last-child #{$editorSelector},.textwidget h1:last-child #{$editorSelector},.textwidget h2:last-child #{$editorSelector},.textwidget h3:last-child #{$editorSelector},.textwidget h4:last-child #{$editorSelector},.textwidget h5:last-child #{$editorSelector},.textwidget h6:last-child #{$editorSelector},
.sow-accordion p:last-child,.textwidget p:last-child,.textwidget pre:last-child,.textwidget h1:last-child,.textwidget h2:last-child,.textwidget h3:last-child,.textwidget h4:last-child,.textwidget h5:last-child,.textwidget h6:last-child{
    margin-bottom: 0;
}



body {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.hentry {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.entry-title {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    color: #7C8790;
    font-size: 1rem;
    line-height: 1.125rem;
    letter-spacing: 0.125rem;
    margin-bottom: 1.125rem;
}

a {
    line-height: inherit;
    color: #0077C8;
    text-decoration: none;
    cursor: pointer;
}

.hentry li {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 0 0 0 1.5rem;
}


/*
 * Basic stiliai
 */


//li margin fix for wysiwig
.hentry .wysiwig-affected li {
    margin-bottom: 0 !important;
}

.hentry p, .hentry p #{$editorSelector} {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
}

.hentry pre, .hentry pre #{$editorSelector} {
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 1.5rem;
    margin: 1.5rem 0 1.5rem;
    background-color: #E5F1F9;
    margin-bottom: 1.5rem;
}

blockquote p, blockquote p #{$editorSelector} {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 1.5rem;
    margin: 1.5rem 0 1.5rem;
    background-color: #FFFBEB;
    border-left: 2px solid #E9EBEC;
    margin-bottom: 1.5rem;
}

.hentry h6, .hentry h6 #{$editorSelector} {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    color: #7C8790;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.125rem;
}

.hentry h5, .hentry h5 #{$editorSelector} {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
}

.hentry h4, .hentry h4 #{$editorSelector} {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1.5rem;
}

.hentry h3, .hentry h3 #{$editorSelector} {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 2rem;
    line-height: 2.375rem;
    margin-bottom: 1.5rem;
}

.hentry h2, .hentry h2 #{$editorSelector} {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 700;
    color: #253746;
    font-size: 3rem;
    line-height: 3.375rem;
    margin-bottom: 1.5rem;
}

.hentry h1, .hentry h1 #{$editorSelector}{
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 4rem;
    line-height: 4.375rem;
    margin-bottom: 1.5rem;
}

.hentry h1.widget-title,
.hentry h2.widget-title,
.hentry h3.widget-title,
.hentry h4.widget-title,
.hentry h5.widget-title,
.hentry h6.widget-title,
.hentry h1.widget-title #{$editorSelector},
.hentry h2.widget-title #{$editorSelector},
.hentry h3.widget-title #{$editorSelector},
.hentry h4.widget-title #{$editorSelector},
.hentry h5.widget-title #{$editorSelector},
.hentry h6.widget-title #{$editorSelector} {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    color: #7C8790;
    font-size: 1rem;
    line-height: 1.125rem;
    letter-spacing: 0.125rem;
}

.widget, .widget #{$editorSelector}{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #253746;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
}

/**
 * Overlaping header
 */
.overlaping-header-wraper{
    position: relative;
}

.overlaping-header{
    position: absolute;
    top: 0px;
    z-index: 999;
    width: 100%;
}


/*
 * Comments Layout Related 
 */
.comment-list, .comment-respond, div#comments > div:last-child {
    clear: both;
}
.comment-list ul, .comment-list li {
    list-style:none;
}
.comment-list .comment-author.vcard > div,.comment-list cite.fn{
    display: inline-block;
}
.comment-list cite.fn:before{
    content: none;
}
.comment-list .comment-author.vcard > img{
    vertical-align: top;
}
.comment-navigation .nav-links > div{
    display: inline-block;
}
.comment-navigation .nav-links .nav-next{
    float:left;
    margin-right: 1em;
}
/*
 */
/*
 * Comments customization
 */
.comment-list .comment-author.vcard > img{
    border-radius: 20px;
    height: 40px;
    width: 40px;
}
.comment-list .comment-text > p{
    margin-top: 8px;
    margin-bottom: 8px;
}
.comment-list .comment-awaiting-moderation{
    background-color: #fff8d6;
}
.comment-list .reply {
    border-width: 1px 0px 0px 0px;
    border-color: #e9ebec;
    border-style: solid;
    padding: 4px;
}
.comment-navigation .nav-links .nav-next a:before{
    content: '<< ';
}
.comment-navigation .nav-links .nav-previous a:after{
    content: ' >>';
}
.nav-next {
    margin-top: 24px;
    margin-bottom: 24px;
}
.nav-previous {
    margin-top: 24px;
    margin-bottom: 24px;
}
.comments-area {
    margin-top: 40px;
}
.screen-reader-text {
    display: none;
}
.comment-respond {
    margin-top: 40px;
}
.comment-body {
    margin: 16px 0px 16px 0px;
}
.fn {
    padding-top: 8px;
}
.comments-title {
    display: none;
}
.hentry .comment-list {
    margin: 0px;
}
.hentry .comment-list > li.comment {
    margin: 0;
}
/*
 */